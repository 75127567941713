<template>
  <template v-if="isMobile && isEarnignsCallsPage">
    <!-- Workaround 的做法，未來新版 Navi 確定上線後拿掉 -->
    <AsyncMobileEarningsCallsNavi />
  </template>
  <template v-else-if="isMobile">
    <AsyncMobileNaviContainer
      :use-vue-router="props.useVueRouter"
      :should-show-search-panel="shouldShowSearchPanel"
      :is-signed-in="isSignedIn"
      :current-user="currentUser"
    />
  </template>
  <template v-else>
    <AsyncDesktopNaviContainer
      :use-vue-router="props.useVueRouter"
      :should-show-search-panel="shouldShowSearchPanel"
    />
  </template>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
import { useBreakpoints } from '@vueuse/core'

import useUserStore from '@/modules/shared/store/useUserStore'

const AsyncDesktopNaviContainer = defineAsyncComponent(() => import('../desktop'))
const AsyncMobileNaviContainer = defineAsyncComponent(() => import('../mobile'))
const AsyncMobileEarningsCallsNavi = defineAsyncComponent(() => import('../mobile/components/ContainerEarningsCalls.vue'))

const props = defineProps({
  useVueRouter: {
    type: Boolean,
    default: false,
  },
  desktopBreakpoint: {
    type: Number,
    default: 768,
  },
  shouldShowSearchPanel: {
    type: Boolean,
    default: true,
  },
})

const breakpoints = useBreakpoints({
  desktop: props.desktopBreakpoint,
})
const isMobile = breakpoints.smaller('desktop')

const { currentUser, isSignedIn } = useUserStore()

const isEarnignsCallsPage = window.location.pathname.includes('/earnings_calls/')

</script>

<style>
/* 實作動態載入所必要的 style 區塊，否則會沒有半行 style 的內容被編譯，導致 manifest.json 裡面沒有該 entry 的 style tag */
body {}
</style>
